<template>
  <div class="clearBox">
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box" style="padding-top: 24px">
      <div class="content-main">
        <!-- 标题 -->
        <div>
          <span class="title">{{nav[0].name}}</span>
        </div>
        <!-- 左侧非地图部分 -->
        <div class="content-main-left">
          <!-- 标段输入项 -->
          <div class="content-main-hw" style="padding-top: 20px">
            <el-form
              :model="form"
              :rules="rules"
              ref="form"
              :inline="true"
              label-position="right"
              label-width="170px"
              size="mini"
            >
              <el-form-item label="公厕名称" prop="wcName" style="margin-bottom: 0px">
                <el-input v-model="form.wcName" placeholder="请输入" maxlength="20"></el-input>
              </el-form-item>
              <el-form-item label="所属标段" prop="bidId">
                <ayl-cascader-for-bid v-model="form.bidId" @change="handleBidChage"></ayl-cascader-for-bid>
              </el-form-item>
              <el-form-item label="开放时间" style="width: 100%">
                <el-time-select
                  placeholder="起始时间"
                  v-model="form.openStartTime"
                  :picker-options="{
                    start: '00:00',
                    step: '00:30',
                    end: '24:00'
                  }"
                ></el-time-select>至
                <el-time-select
                  placeholder="结束时间"
                  v-model="form.openEndTime"
                  :picker-options="{
                    start: '00:00',
                    step: '00:30',
                    end: '24:00',
                    minTime: form.openStartTime
                  }"
                ></el-time-select>
              </el-form-item>
              <el-form-item label="管理单位名称">
                <el-input v-model="form.manageUnit" placeholder="请输入" maxlength="20"></el-input>
              </el-form-item>
              <el-form-item label="管理类别" prop="manageType">
                <el-select v-model="form.manageType" placeholder="请选择" filterable>
                  <el-option
                    v-for="(item, idx) in $enums_hw.manageType.list"
                    :key="idx"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="管理配员(名)">
                <el-input v-model="form.demandWorker" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="建成时间">
                <el-date-picker
                  v-model="form.buildTime"
                  type="date"
                  value-format="timestamp"
                  placeholder="选择日期"
                ></el-date-picker>
              </el-form-item>
              <el-form-item label="管理人员电话" prop="demandWorkerPhone">
                <el-input v-model="form.demandWorkerPhone" placeholder="请输入" maxlength="11"></el-input>
              </el-form-item>
              <el-form-item label="管理人员姓名">
                <el-input v-model="form.demandWorkerName" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="男厕站位数量">
                <el-input v-model="form.maleStandNum" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="占地面积(m²)">
                <el-input v-model="form.area" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="女厕蹲位数量">
                <el-input v-model="form.femaleSquatNum" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="男厕蹲位数量">
                <el-input v-model="form.maleSquatNum" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="镜子数量">
                <el-input v-model="form.mirrorNum" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="水龙头数量">
                <el-input v-model="form.faucetNum" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="风扇数量">
                <el-input v-model="form.fanNum" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="抽风机数量">
                <el-input v-model="form.suctionFanNum" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="公厕类别">
                <el-select v-model="form.wcType" placeholder="请选择" filterable>
                  <el-option
                    v-for="(item, idx) in $enums_hw.wcType.list"
                    :key="idx"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="投资金额估算(万元)">
                <el-input v-model="form.investFund" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="经费标准(元/座)">
                <el-input v-model="form.fundStandard" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="是否独立建筑">
                <el-checkbox v-model="form.isIndependent">是</el-checkbox>
              </el-form-item>
              <el-form-item label="有无洗手液">
                <el-checkbox v-model="form.hasHandSanitizer">有</el-checkbox>
              </el-form-item>
              <el-form-item label="有无化粪池">
                <el-checkbox v-model="form.hasSepticTank">有</el-checkbox>
              </el-form-item>
              <el-form-item label="有无收费">
                <el-checkbox v-model="form.freeOrNot">有</el-checkbox>
              </el-form-item>
              <el-form-item label="有无免费厕纸供应">
                <el-checkbox v-model="form.hasFreePaper">有</el-checkbox>
              </el-form-item>
              <el-form-item label="地址" style="width: 100%">
                <el-input
                  v-model="form.wcAddress"
                  placeholder="请输入"
                  maxlength="50"
                  class="el-input-bigwidth"
                ></el-input>
              </el-form-item>
              <!-- 公厕附件-->
              <el-form-item label="公厕附件" style="width: 100%">
                <ayl-upload-single-image v-model="form.wcImageUrls"></ayl-upload-single-image>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <!-- 地图部分 -->
        <ayl-map v-model="mapData" icon="gc"></ayl-map>
      </div>

      <div class="tac mt40px">
        <el-button type="success" class="btn-success-hw" @click="onSubmit">保存</el-button>
        <el-button class="btn-hw" @click="onSubmitAndNext">保存并录入下一条</el-button>
        <el-button type="danger" class="btn-danger" style="margin-left: 10px" @click="onClose">关闭</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import add from "@/mixins/add";
export default {
  mixins: [add],

  data() {
    return {
      nav: [{ name: "新增公厕" }],
      debug: 0,
      mapData: {
        //地图数据
        lnglat: null, //经纬度，数组，形如[118,36]
        scope: null //标段范围，经纬度点阵数组，形如[[118,36],[117,32],[116,113]]
      },
      form: {
        bidId: null,
        wcName: null, //公厕名称
        wcType: null, //公厕类型
        area: null, //占地面积
        wcImageUrls: null, //公厕图片链接
        wcCoordinateScope: null, //公厕位置坐标
        wcAddress: null, //地址
        suctionFanNum: null, //抽风机数量
        openStartTime: null, //开放起始时间
        openEndTime: null, //开放截止时间
        mirrorNum: null, //镜子数量
        manageUnit: null, //管理单位名称
        manageType: null, //管理类别
        maleStandNum: null, //男厕站位数
        maleSquatNum: null, //男厕蹲位数量
        investFund: null, //投资金额估算
        isIndependent: null, //是否独立建筑
        isDrawn: 0, //是否绘制
        hasSepticTank: null, //有无化粪池
        hasHandSanitizer: null, //有无洗手液
        hasFreePaper: null, //有无免费厕纸供应
        freeOrNot: null, //有无收费
        fundStandard: null, //经费标准
        femaleSquatNum: null, //女厕蹲位数量
        faucetNum: null, //水龙头数量
        fanNum: null, //风扇数量
        demandWorkerPhone: null, //管理人员电话
        demandWorkerName: null, //管理人员姓名
        demandWorker: null, //管理配员
        buildTime: null, //建成时间
        bidMaintainUnit: null //养护企业
      },
      rules: {
        bidId: [
          {
            required: true,
            message: "请选择标段",
            trigger: "change"
          }
        ],
        wcName: [
          { required: true, message: "请输入1-20位公厕名称", trigger: "blur" },
          { validator: this.$validator.isContainBlank }
        ],
        manageType: [
          {
            required: true,
            message: "请选择管理类别",
            trigger: "change"
          }
        ],
        demandWorkerPhone: {
          trigger: "blur",
          validator: this.$validator.validatePhoneBlank
        }
      }
    };
  },
  methods: {
    async submit() {
      let self = this;
      await self.$api_hw.publicToilets_insert({
        sanitationPublicToiletVO: _fixData(self.form)
      });
      function _fixData(d) {
        for (let key in d) {
          switch (key) {
            case "isIndependent":
            case "hasHandSanitizer":
            case "hasSepticTank":
            case "freeOrNot":
            case "hasFreePaper":
              d[key] = d[key] * 1;
              break;
            case "wcCoordinateScope":
              if (self.mapData.lnglat) {
                d["isDrawn"] = 1;
                d[key] = JSON.stringify(self.mapData.lnglat);
              }else{
                d["isDrawn"] = 0;
                d[key] = "";
              }
              break;
          }
        }
        self.log(d);
        return d;
      }
    }
  }
};
</script>

<style lang='sass' scoped>
.clearBox
  .content-box
    .content-main
      display: inline-block
      width: 100%
      &-left
        float: left
        width: 808px
      &-right
        float: left
        height: 777px
        width: calc(100% - 808px)
        background: #EDEDED
  .order
    overflow-x: visible !important
    &-type
      display: inline-block
      padding-right: 14px
      font-size: 14px
      font-family: Microsoft YaHei
      font-weight: 400
      color: rgba(102,102,102,1)
  .title
    width: 64px
    height: 17px
    font-size: 16px
    font-family: Microsoft YaHei
    font-weight: 400
    color: $-color-primary-3
    line-height: 18px
  .el-checkbox-group
    display: inline-block
  .map-popover
    position: absolute
    z-index: 2
    top: 12px
    right: 12px
    width: 180px
    height: 32px
    background: rgba(255,255,255,1)
    box-shadow: 0px 2px 8px 0px rgba(31,46,41,0.3)
    border-radius: 2px

  /deep/ .el-radio__input.is-checked .el-radio__inner
    background: $-color-primary-3
    border-color: $-color-primary-3
  /deep/ .el-radio__input.is-checked + .el-radio__label
    color: $-color-primary-3
  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner
    background: $-color-primary-3
    border-color: $-color-primary-3
  /deep/ .el-checkbox__input.is-checked + .el-checkbox__label
    color: $-color-primary-3
  /deep/ .el-form-item
    width: 48%
  /deep/ .el-input--mini
    width: 170px
  .el-date-editor
    width: 170px
  .el-date-editor-bigwidth
    width: 350px
  .el-input-bigwidth
    width: 350px

.add-item
  width: 100%
  // height: 220px
  border: 1px solid rgba(225,225,225,1)
  border-radius: 2px
  margin-bottom: 20px
  .add-item-main
    margin: 10px 0

.add-btn
  margin: 20px 0
  text-align: center
  .btn
    width: 64px
    height: 28px
    border: 1px solid rgba(26,188,156,1)
    border-radius: 4px
    color: #1ABC9C
</style>
